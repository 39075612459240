
import { defineComponent, ref, onMounted, computed } from 'vue';
import X from '@/components/web3/x.vue';
import useTokens from '../../composables/PolarisFinance/useTokens';
import useWeb3 from '@/services/web3/useWeb3';
import useStake from '../../composables/PolarisFinance/useStake';
import { parseFixed } from '@ethersproject/bignumber';

import useTransactions from '@/composables/useTransactions';
import { TransactionResponse } from '@ethersproject/providers';
import useEthers from '../../composables/useEthers';

/**
 * STATE
 */
const textInput = ref<HTMLInputElement>();
/**
 * LIFECYCLE
 */
onMounted(() => {
  textInput.value?.focus();
});
export default defineComponent({
  components: {
    X,
  },
  props: {
    balance: { type: String, default: '0' },
    token: String,
    address: { type: String, default: '' },
    unstake: { type: Boolean, default: false},
    
  },
  setup(props, { emit }) {
    
    const { getProvider } = useWeb3();
    const { addTransaction } = useTransactions();
    
    
    const address = props.address;
    const { account } = useWeb3();
    const { txListener } = useEthers();
    return {
      txListener,
      getProvider,
      addTransaction,
      emit,
      account,
    };
  },
  async beforeMount(){
    const { balance } = useStake();
    this.stakedBalance = await balance(this.poolAddress, this.account);
  },
  data() {
    return {
      inputValue: '0.0',
      stakedBalance: '0',
      poolAddress:this.address,
      confirming:false,
    };
  },
  computed:{
    
  },
  methods: {
    maxUnstake(){
      this.inputValue = this.stakedBalance;
    },
    maxBalance() {
      this.inputValue = this.balance;
    },
    async confirm(amount: string) {

      
      const formatedAmount = parseFixed(amount, 18);
      console.log(formatedAmount);
      const {withdraw, deposit } = useStake();
      console.log(this.poolAddress);

      this.confirming=true;

      if(this.unstake){
        const tx = await withdraw(this.poolAddress, formatedAmount, this.getProvider());
        const txHandler = (tx: TransactionResponse): void => {
          this.addTransaction({
            id: tx.hash,
            type: 'tx',
            action: 'stake',
            summary: 'unstake lp from xpolarRewardPool',
          });
        };
        txHandler(tx);
        this.txListener(tx, {
          onTxConfirmed: () => {
            this.confirming=false;
            this.emit('close');
            this.emit('unstakeConfirmed');
          },
          onTxFailed: () => {
            this.confirming=false;
          },
        });
      }else{
        const tx = await deposit(this.poolAddress, formatedAmount, this.getProvider());
        const txHandler = (tx: TransactionResponse): void => {
          this.addTransaction({
            id: tx.hash,
            type: 'tx',
            action: 'stake',
            summary: 'deposit lp to xpolarRewardPool',
          });
        };
        txHandler(tx);
        this.txListener(tx, {
          onTxConfirmed: () => {
            this.confirming=false;
            this.emit('close');
            this.emit('stakeConfirmed');
          },
          onTxFailed: () => {
            this.confirming=false;
          },
        });
      }
      
    },
  },

  emits: ['close', 'stakeConfirmed','unstakeConfirmed'],
});
