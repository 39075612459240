import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-73369610")
const _hoisted_1 = { class: "container mx-auto" }
const _hoisted_2 = { class: "headline mx-auto my-[55px] text-center" }
_popScopeId()

import { computed, defineAsyncComponent } from 'vue';

import AppHero from '@/components/heros/AppHero.vue';
import useFathom from '@/composables/useFathom';
import { EXTERNAL_LINKS } from '@/constants/links';
import useWeb3 from '@/services/web3/useWeb3';

import HeroConnectWalletButton from './HeroConnectWalletButton.vue';
// import step1 from './StepIcons/1.vue'


/**
 * TYPES
 */
 type Props = {
  headline:string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    headline: { type: String, required: true, default: "" }
  } as unknown as undefined,
  setup(__props: {
  headline:string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { isWalletReady, isWalletConnecting } = useWeb3();
const { trackGoal, Goals } = useFathom();

/**
 * COMPUTED
 */
const classes = computed(() => ({
  ['h-64']: !isWalletReady.value && !isWalletConnecting.value,
  ['h-44']: isWalletReady.value || isWalletConnecting.value,
}));
const stepIcon = computed(() => import( `./StepIcons/${props.step}.vue`));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(__props.headline), 1)
  ]))
}
}

})